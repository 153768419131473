import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useCallback, useState } from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";

type Props = {
  conferenceId: string;
  onSuccess: () => void;
};

type ProcessingError = {
  row: number;
  error: string;
};

type SendError = {
  reason: string;
};

const DialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BulkInvite = ({ conferenceId, onSuccess }: Props) => {
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [processErrors, setProcessErrors] = useState<ProcessingError[]>([]);
  const [sendErrors, setSendErrors] = useState<SendError[]>([]);
  const [dialogTitle, setDialogTitle] = useState("");
  const [state, setState] = useState({
    error: "",
    loading: false,
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setState({ ...state, error: "" });
    setSendErrors([]);
    setProcessErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    accept: [".csv"],
    maxFiles: 1,
    maxSize: 1000000,
  });

  const handleClick = async () => {
    //No files
    if (acceptedFiles.length === 0) {
      setState({ ...state, error: "Please select a file" });
      return;
    }

    const token = localStorage.getItem("SavedToken");
    try {
      setState({ ...state, loading: true, error: "" });
      setSendErrors([]);
      setProcessErrors([]);

      let formData = new FormData();
      formData.append("csv_invites", acceptedFiles[0]);
      formData.append("conferenceId", conferenceId);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/invite/attendee/send-bulk`,
        formData,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      onSuccess();
    } catch (error: any) {
      setState({ ...state, error: "Request failed", loading: false });
      if (
        error.response.data.errors &&
        error.response.data.message === "Validation errors"
      ) {
        setDialogTitle("Error processing file for sending invites");
        setProcessErrors(error.response.data.errors);
        setErrorDialogOpen(true);
      } else if (error.response.data.errors) {
        setDialogTitle(error.response.data.message);
        setSendErrors(error.response.data.errors);
        setErrorDialogOpen(true);
      }
    }
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
    setProcessErrors([]);
    setSendErrors([]);
    setState({ ...state, error: "" });
  };

  return (
    <>
      {state.error && <Alert severity="error">{state.error}</Alert>}
      <Box
        {...getRootProps()}
        sx={{ p: 5, border: "2px dashed #6b6b6b", textAlign: "center", mt: 2 }}
      >
        <input {...getInputProps()} />
        {acceptedFiles.length === 0 ? (
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FileUploadIcon />
            Drop file or click to upload a .csv invite list
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            File: {acceptedFiles[0].name}
          </Typography>
        )}
      </Box>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ my: 3 }}
        startIcon={<SendIcon />}
        disabled={state.loading || acceptedFiles.length === 0}
      >
        Send Bulk Invites
      </Button>

      <Dialog
        open={errorDialogOpen}
        TransitionComponent={DialogTransition}
        keepMounted
        onClose={handleErrorDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <ul style={{ color: "red" }}>
              {processErrors.map((x) => (
                <li>
                  Row: {x.row}, Error: {x.error}{" "}
                </li>
              ))}
              {sendErrors.map((x) => (
                <li>{x.reason}</li>
              ))}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkInvite;
