import { Box, Button, Divider, Grid, List, ListItem, ListItemText, Modal, Typography, } from "@mui/material";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import {
  Attendee,
  EventDetails,
  EventPresenter,
  Invite,
  Registrant,
  Users,
} from "../typings/interfaces";
import { formatInTimeZone } from 'date-fns-tz';
import frenchLocale from 'date-fns/locale/fr-CA';
import PresenterItem from "../components/PresenterItem";
import RepItem from "../components/RepItem";
import AddIcon from "@mui/icons-material/Add";
import CreatePresenterDialog from "../components/CreatePresenterDialog";
import EditPresenterDialog from "../components/EditPresenterDialog";
import RegistrantsList from "../components/RegristrantsList";
import EmailIcon from "@mui/icons-material/MailOutline";
import InviteAttendeeDialog from "../components/InviteAttendeeDialog";
import AssociateRepForm from "../components/AssociateRepForm";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AppContext from "../utils/Auth";
import AttendeesList from "../components/AttendeesList";

const ViewEventPage = () => {
  const params = useParams();
  const { hasRole } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [event, setEvent] = useState<EventDetails | null>(null);
  const [reps, setReps] = useState<Users[]>([]);
  const [registrantRefreshCounter, setRegistrantRefreshCounter] = useState(0);
  const [registrants, setRegistrants] = useState<Registrant[]>([]);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [updateReps, setUpdateReps] = useState(0);
  const [editPresenter, setEditPresenter] = useState<EventPresenter | undefined>(undefined);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const toggleModal = () => {
      setOpenModal(!openModal);
  }

  const [dialogState, setDialogState] = useState({
    presenterEdit: false,
    presenter: false,
    invite: false,
  });

 type Props = {
  conferenceId: string | undefined;
 }
  // Get only the actual presenter since Katie and the Rep are added
  const FilterEventPresenters = ({ conferenceId}: Props) => {
    if(conferenceId && event){

      const filteredPresenters = reps.length > 0 
      ? event.presenters?.filter(function(presenter) { 
        return presenter.email !== reps[0].email && presenter.email !== 'krakoczy@operaticagency.com'
      }) 
      : event.presenters?.filter(function(presenter) { 
        return presenter.email !== 'krakoczy@operaticagency.com'
      })

      return (
        <> { filteredPresenters.map((presenter) => {
          return (
            <PresenterItem
              key={presenter.display_name}
              presenter={presenter}
              handleEditPresenter={handleEditPresenter}
              handleDeletePresenter={handleDeletePresenter}
              conferenceId={conferenceId}
            />
          )
        })}
        </> 
      )
    }
    return <></>
  }

  // Determines if there is an actual presenter (not just katie or rep)
  const hasActualPresenter = () => {
    console.log("reps", reps)
    console.log("event.presenters.length", event?.presenters?.length)
    if ( (event && event.presenters.length === 2 && reps.length === 0) || ( event && event.presenters.length >= 3 && reps.length === 1)) {
      return true
    }
    return false;
  }

  // Delete the event 
  const deleteEvent = async (id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      const token = localStorage.getItem("SavedToken");
      await axios.delete(`${process.env.REACT_APP_API_URL}/events/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      navigate('/');
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  const changeDialogState =
    (dialog: "presenterEdit" | "presenter" | "invite", isOpen: boolean) => () => {
      setDialogState({ ...dialogState, [dialog]: isOpen });
  };


   // Add a presenter to the event
  const handleAddPresenter = (data: EventPresenter, repPresenter?: EventPresenter ) => {
    if (!event) {
      return;
    }

    if(repPresenter) {
      setEvent({ ...event, presenters: [...event.presenters, data, repPresenter] });
    }
    else {
      setEvent({ ...event, presenters: [...event.presenters, data] });
    }
    
  };

  // Delete a presenter from the event
  const handleDeletePresenter = async (id: string[]) => {
    if (!event) {
      return;
    }

    let presenters: EventPresenter[] = [];

    presenters = event.presenters.filter((presenter) => !id.includes(presenter.presenter_id));

    setEvent({ ...event, presenters });
  };

  const handleEditPresenter = (presenter_id: string) => {
    console.log('in edit presenter');
    if (!event) {
      return;
    }

    const presenter = event.presenters.find(
      (presenter) => presenter.presenter_id === presenter_id
    );

    setEditPresenter(presenter);
    return changeDialogState("presenterEdit", true)();
  }

  const handleUpdatePresenter = async () => {
    if (!event) {
      return;
    }
    await getEventData();
    return changeDialogState("presenterEdit", false)();
  };


  // Send an invite to a registrant
  const handleSendInvite = async (attendee: Invite) => {
    try {
      const token = localStorage.getItem("SavedToken");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/invite/attendee/send`,
        { ...attendee, conferenceId: params.id },
        {
          headers: {
            authorization: `${token}`,
          },
        }
      )
      refreshRegistrants();
      return {success: true, email: attendee.email}
    }
    catch (err) {
      return {success: false, email: attendee.email}
    }

  };


  const refreshRegistrants = () => {
    setRegistrantRefreshCounter(registrantRefreshCounter + 1);
  };

  const refreshReps = async () => {
    setUpdateReps(updateReps + 1);
  };


  // Send an invite to a registrant
  const deleteRegistrants = async (email: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    const token = localStorage.getItem("SavedToken");
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/invite/attendee`, {
        headers: {
          authorization: `${token}`,
        },
        params: {
          email,
          conferenceId: params.id,
        },
      });
      const filteredRegistrants = registrants.filter(
        (registrant) => registrant.email !== email
      );
      setRegistrants(filteredRegistrants);
    } catch (error) {
      console.log(error);
      alert(t("attendee_delete_error"));
    }
  };

  // Get event data 

  const getEventData = async () => {
    setError("");
    const token = localStorage.getItem("SavedToken");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/events/event/${params.id}`,
      {
        headers: {
          authorization: token || "",
        },
      }
    );

    if(response) {
      const timeZoneArray = response.data.conference.timeZone.split("/");
      const timeZone = timeZoneArray[1];
      response.data.conference.translatedTimezone = timeZone;
    }
    

    const responseRep = await axios.get(
      `${process.env.REACT_APP_API_URL}/inviterep/${params.id}`,
      {
        headers: {
          authorization: token || "",
        },
      }
    );
    setEvent(response.data.conference);
    setReps(responseRep.data.allReps);
  
  }

  useEffect(() => {
    const getEvent = async () => {
      try {
        setError("");
        await getEventData();
      } catch (error) {
        setError("Failed to get event details");
      }
    };
    getEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, updateReps]);

  useEffect(() => {
    const getRegistrants = async () => {
      try {
        const token = localStorage.getItem("SavedToken");
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/invite/attendee?conferenceId=${params.id}`,
          {
            headers: {
              authorization: token || "",
            },
          }
        );
        setRegistrants(res.data.registrations);
      } catch (error) {
        //TODO: Seperate failure for registrants ?
        setError("Failed to get event details");
      }
    };
    getRegistrants();
  }, [params.id, registrantRefreshCounter]);

  useEffect(() => {
    const getAttendees = async () => {
      try {
        const token = localStorage.getItem("SavedToken");
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/events/participants/${params.id}`,
          {
            headers: {
              authorization: token || "",
            },
          }
        );
        setAttendees(res.data.registrations);
      } catch (error) {
        //TODO: Seperate failure for registrants ?
        setError("Failed to get event details");
      }
    };
    getAttendees();
  }, [params.id]);

  if (error) {
    return (
      <Layout>
        <Box my={2}>
          <Typography variant="h5" color="red">
            {error}
          </Typography>
        </Box>
      </Layout>
    );
  }

  if (!event) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      <Box my={4}>
        <Grid item>
          <Typography
            display="inline-block"
            variant="h3"
            color="#3D3D3D"
            fontFamily="Roboto"
            sx={{ marginBottom: "20px", borderBottom: "5px solid #158BEE", fontWeight:"500" }}
          >
            <span>{event.title}</span>
          </Typography>
        </Grid>
        <Typography variant="body2" color="#3D3D3D" display="inline-flex">
          <Box component="span" sx={{ marginRight: "5px" }}>
            <EventIcon sx={{ fontSize: "20px" }} />
          </Box>
          <Box component="span" sx={{ marginRight: "25px" }}>
            {i18next.language === "en" && formatInTimeZone(new Date(event.start_time), event.timeZone, "MMMM dd, YYY")} 
            {i18next.language === "fr" && formatInTimeZone(new Date(event.start_time), event.timeZone, "dd MMMM, YYY", { locale: frenchLocale })}
          </Box>
        </Typography>
        <Typography variant="body2" color="#3D3D3D" display="inline-flex">
          <Box component="span" sx={{ marginRight: "5px" }}>
            <AccessTimeIcon sx={{ fontSize: "20px" }} />
          </Box>
          <Box component="span" sx={{ marginRight: "5px" }}>
            {i18next.language === "en" && `${formatInTimeZone(new Date(event.start_time), event.timeZone, "h:mm aa")} ${t(`time_zones.${event.translatedTimezone}`)}`} 
            {i18next.language === "fr" && `${formatInTimeZone(new Date(event.start_time), event.timeZone, "H 'h 'mm")}, ${t(`time_zones.${event.translatedTimezone}`)}`}
          </Box>
        </Typography>
        <Typography
          variant="body2"
          color="#3D3D3D"
          display="flex"
          sx={{ marginTop: "10px" }}
        >
          <Box component="span" sx={{ marginRight: "5px" }}>
            <TimelapseIcon sx={{ fontSize: "20px" }} />
          </Box>
          <Box component="span" sx={{ marginRight: "5px" }}>
            {event.duration} minutes
          </Box>
        </Typography>
        <Typography
          variant="body2"
          display="flex"
          sx={{ marginTop: "10px" }}
          color="#3D3D3D"
        >
          {event.eventType ? `${t("view_event_event_type")} ${t(`${event.eventType}`)}` : t("no_type_provided")}
        </Typography>
        <Typography
          variant="body2"
          display="flex"
          sx={{ marginTop: "10px" }}
          color="#3D3D3D"
        >
          {event.purpose ? `${t("view_event_location_label")} ${event.purpose}` : ""}
        </Typography>
        { hasRole(["sudo", "admin", "msl", "rep"]) && 
          <Button
            size="small"
            variant="contained"
            color="warning"
            onClick={() => {
              navigate(`/edit/event/${event.id}`);
            }}
            startIcon={<EditOutlinedIcon />}
            sx={{ marginRight: "30px", marginTop: "30px" }}
          >
            {t("edit_event_button")}
          </Button>
        }
        { hasRole(["sudo", "admin", "msl"]) && 
          <Button
            size="small"
            variant="contained"
            color="error"
            startIcon={<DeleteOutlinedIcon />}
            sx={{ marginRight: "20px", marginTop: "30px" }}
            onClick={() => {
              deleteEvent(event.id);
            }}
          >
            {t("delete_event_button")}
          </Button>
        }
      </Box>
      <Divider />
      
      <Box my={4}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" color="#3D3D3D">
              {t("presenters_title")}
            </Typography>
          </Grid>
          { hasRole(["sudo", "admin", "msl", "rep"]) &&
            <Grid item>
              {/* // show the button if presenters length is either 1 or 2 and  */}
              { !hasActualPresenter() &&
                <Button
                  onClick={changeDialogState("presenter", true)}
                  sx={{
                    backgroundColor: "#158BEE",
                    borderRadius: "4px",
                    width: "260px",
                  }}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  {t("add_presenter_button")}
                </Button>
              }
            </Grid>
          }
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            boxShadow: "10px 10px 5px 12 black",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "#FFFFFF", border:"1px solid rgba(224, 224, 224, 1)"}}>
            { params.id  && (
              
               <FilterEventPresenters conferenceId={params.id} />
              )
            }
            {!hasActualPresenter() && (
              <ListItem
                alignItems="flex-start"
              >
                <ListItemText
                  primary={t("no_presenter")}
                />
              </ListItem>
            )}
          </List>
        </Grid>
      </Box>
      { hasRole(["sudo", "admin", "msl"]) &&
        <Box my={4}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" color="#3D3D3D">
                {t("rep_title")}
              </Typography>
            </Grid>
            <Grid item>
              {reps.length === 0 &&
              <Button
                disabled={event.presenters.length === 1}
                onClick={toggleModal}
                sx={{
                  backgroundColor: "#158BEE",
                  borderRadius: "4px",
                  width: "260px",
                }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                {t("add_rep_button")}
              </Button>
              }
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              boxShadow: "10px 10px 5px 12 black",
            }}
          >
            <List sx={{ width: "100%", bgcolor: "#FFFFFF", border:"1px solid rgba(224, 224, 224, 1)"}}>
              {reps.length === 0 &&
                  <RepItem
                    hasRep={false}
                    refreshReps={refreshReps}
                    conferenceId={event.id}
                  />
              }
              {reps.map((rep) => (
                <>
                  <RepItem
                    key={rep.name}
                    hasRep={true}
                    rep={rep}
                    refreshReps={refreshReps}
                    conferenceId={event.id}
                  />
                </>
              ))}
            </List>
          </Grid>
        </Box>
      }
      <Box display={"flex"} flexDirection={"row"}>
        <Grid>
          <Typography paragraph pr={10} color="#3D3D3D">
            {t("SETT.callout")}
          </Typography>
        </Grid>
        <Grid>
          <Button
            onClick={() => navigate("/sett-page/")}
            sx={{
              backgroundColor: "#158BEE",
              borderRadius: "4px",
              width: "260px",
            }}
            variant="contained"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {t("view_event_download_invitation")}
          </Button>
        </Grid>
      </Box>
      <Divider />
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" color="#3D3D3D">
              {t("registrants_title")}
            </Typography>
          </Grid>
          <Grid item>
            { hasActualPresenter() &&
              <Button
                onClick={changeDialogState("invite", true)}
                color="primary"
                variant="contained"
                sx={{
                  backgroundColor: "#158BEE",
                  borderRadius: "4px",
                  width: "260px",
                }}
                startIcon={<EmailIcon />}
              >
                {t("invite_attendee_button")}
              </Button>
            }
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#FFF",
          }}
        >
          <RegistrantsList
            registrants={registrants}
            deleteRegistrants={deleteRegistrants}
            handleAddAttendee={handleSendInvite}
          />
        </Grid>
      </Box>
      <Box my={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h4" color="#3D3D3D">
              {t("attendees_title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            backgroundColor: "#FFF",
          }}
        >
          <AttendeesList
            attendees={attendees}
          />
        </Grid>
      </Box>
      <Modal
        open={openModal}
        onClose={toggleModal}
        onBackdropClick={toggleModal}
        style={{display:'flex',alignItems:'center',justifyContent:'center'}}
      >
        <AssociateRepForm
          toggleModal={toggleModal}
          refreshReps={refreshReps}
        />
      </Modal>
      { editPresenter &&
        <EditPresenterDialog
          handleDialogClose={changeDialogState("presenterEdit", false)}
          handleUpdatePresenter={handleUpdatePresenter}
          open={dialogState.presenterEdit}
          presenter={editPresenter}
        />
      }
      <CreatePresenterDialog
        handleDialogClose={changeDialogState("presenter", false)}
        handleAddPresenter={handleAddPresenter}
        reps={reps}
        open={dialogState.presenter}
      />
      <InviteAttendeeDialog
        handleDialogClose={changeDialogState("invite", false)}
        handleAddAttendee={handleSendInvite}
        refreshRegistrants={refreshRegistrants}
        open={dialogState.invite}
      />
    </Layout>
  );
};

export default ViewEventPage;
