import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid, TextField, InputLabel, FormControl, Select, FormHelperText } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

type Props = {
  formik: any;
  edit: Boolean;
  buttonDisabled: boolean;
};

const EventForm = ({ formik, edit, buttonDisabled }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={3}
          style={{ marginTop: "25px" }}
          justifyContent="center"
          id="edit_event"
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="medium"
              id="title"
              label={t("create_event_form_title")}
              type="text"
              color="info"
              autoComplete="title"
              variant="outlined"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              focused
            /> 
          </Grid>
          {formik.values.purpose && <Grid item xs={12}>
            <TextField
              fullWidth
              size="medium"
              id="purpose"
              label={t("create_event_form_location_label")}
              type="text"
              color="info"
              autoComplete="purpose"
              variant="outlined"
              value={formik.values.purpose}
              onChange={formik.handleChange}
              error={formik.touched.purpose && Boolean(formik.errors.purpose)}
              helperText={formik.touched.purpose && formik.errors.purpose}
              focused
              disabled
            />
          </Grid> }
          <Grid item xs={12}>
            <FormControl
              fullWidth
              focused
              variant="filled"
              error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
            >
              <InputLabel> {t("create_event_form_timezone")} </InputLabel>
              <Select
                id="timeZone"
                name="timeZone"
                disabled
                type="text"
                color="info"
                value={formik.values.timeZone}
                onChange={formik.handleChange}
                error={formik.touched.language && Boolean(formik.errors.timeZone)}
              >
                <MenuItem value="Canada/Eastern">{t("time_zones.Eastern")}</MenuItem>
                <MenuItem value="Canada/Pacific">{t("time_zones.Pacific")}</MenuItem>
                <MenuItem value="Canada/Central">{t("time_zones.Central")}</MenuItem>
                <MenuItem value="Canada/Atlantic">{t("time_zones.Atlantic")}</MenuItem>
                <MenuItem value="Canada/Newfoundland">{t("time_zones.Newfoundland")}</MenuItem>
                <MenuItem value="Canada/Mountain">{t("time_zones.Mountain")}</MenuItem>
              </Select>
              {formik.touched.timeZone && (
                <FormHelperText>{formik.errors.timeZone}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} justifyContent="center">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            
              <DateTimePicker
                renderInput={(params) => (
                  <TextField
                  fullWidth
                    {...params}
                    error={
                      formik.touched.startTime &&
                      Boolean(formik.errors.startTime)
                    }
                    helperText={
                      formik.touched.startTime && formik.errors.startTime
                    }
                  />
                )}
                label={t("create_event_form_date_label")}
                value={formik.values.startTime}
                minDate={new Date()}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("startTime", value);
                  } else {
                    formik.setFieldValue("startTime", "");
                  }
                }}
              />
              
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="medium"
              id="durationMinutes"
              name="durationMinutes"
              label={t("create_event_form_duration_label")}
              type="number"
              color="info"
              autoComplete="durationMinutes"
              variant="outlined"
              value={formik.values.durationMinutes}
              onChange={formik.handleChange}
              error={
                formik.touched.durationMinutes &&
                Boolean(formik.errors.durationMinutes)
              }
              helperText={
                formik.touched.durationMinutes && formik.errors.durationMinutes
              }
              focused
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              focused
              variant="filled"
              error={formik.touched.eventType && Boolean(formik.errors.eventType)}
            >
              <InputLabel> {t("create_type")} </InputLabel>
              <Select
                disabled={true}
                id="eventType"
                name="eventType"
                type="text"
                color="info"
                value={formik.values.eventType}
                onChange={formik.handleChange}
                error={formik.touched.language && Boolean(formik.errors.eventType)}
              >
                <MenuItem value="Live">{t("Live")}</MenuItem>
                <MenuItem value="Virtual">{t("Virtual")}</MenuItem>
                <MenuItem value="Hybrid">{t("Hybrid")}</MenuItem>
              </Select>
              {formik.touched.eventType && (
                <FormHelperText>{formik.errors.eventType}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              focused
              variant="filled"
              error={formik.touched.language && Boolean(formik.errors.language)}
            >
              <InputLabel> {t("create_language")} </InputLabel>
              <Select
              disabled={true}
                id="language"
                name="language"
                type="text"
                color="info"
                value={formik.values.language}
                onChange={formik.handleChange}
                error={formik.touched.language && Boolean(formik.errors.language)}
              >
                <MenuItem value="English">{t("English")}</MenuItem>
                <MenuItem value="French">{t("French")}</MenuItem>
              </Select>
              {formik.touched.language && (
                <FormHelperText>{formik.errors.language}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: 20 }}>
            <Button
              fullWidth
              variant={"contained"}
              type={"submit"}
              disabled={buttonDisabled}
            >
              {edit ? t("edit_event_form_button") : t("create_event_form_button")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EventForm;
