import { Container, Box } from "@mui/material";
import React from "react";
import Nav from "./Nav";
import Footer from "./Footer"

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Layout = (props: Props) => {
  return (
    <React.Fragment>
      <Nav />
      <Box className="background" 
      sx={{backgroundColor: "#FAFAFA", width: '100%', minHeight: '100vh', paddingTop: '5px', paddingBottom: '5px', position:"relative", overflow:"hidden"}}>
      <div style={{
        position: "fixed",
        bottom: "0",
        right: "-15%",
        width: "45%",
        zIndex:'0',
      }}>
        <img
        src="/assets/blue-compas-x2.png"
        alt="Compas Logo"
        style={{ maxWidth: "100%", marginBottom: 0 , color:"#3D3D3D", height:"auto"}}
      />
      </div>
      <Container sx={props.style}>{props.children}</Container>
      </Box>
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
