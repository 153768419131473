import axios from "axios";
import { useContext } from "react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import EventForm from "../components/EventForm";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { Event } from "../typings/interfaces";
import * as yup from "yup";
import { Alert, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppContext from "../utils/Auth";

const EditEventPage = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const navigate = useNavigate();
  const { hasRole } = useContext(AppContext);
  const [event, setEvent] = useState<Event | null>(null);
  const success_message = t("update_event_success");
  const error_message = t("update_event_error");
  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  const validationSchema = yup.object({
  title: yup.string().required(t("create_event_form_title_error")),
  purpose: yup.string(),
  timeZone: yup.string().required(t("create_event_form_timzone_error")),
  startTime: yup.date()
  // .min(new Date(), t("create_event_form_date_past_error"))
  .required(t("create_event_form_date_error")),
  durationMinutes: yup.number().required(t("create_event_form_duration_error")),
  eventType: yup.string().required(t("create_event_form_duration_error")),
  language: yup.string().required(t("create_event_form_duration_error")),
});

  const formik = useFormik({
    initialValues: {
      title: "",
      purpose: "",
      timeZone:"",
      startTime: "",
      durationMinutes: "",
      eventType: "",
      language: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setState({ ...state, loading: true, error: "", success: "" });
      const token = localStorage.getItem("SavedToken");
      const startTime = new Date(values.startTime);
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/events/${id}`,
          { ...values, startTime: format(startTime, "yyyy-MM-dd HH:mm").toString() },
          { headers: { authorization: token || "" } }
        );
        formik.resetForm({ values });
        setState({ ...state, success: success_message, loading: false });
      } catch (error) {
        setState({
          ...state,
          error: error_message,
          loading: false,
        });
      }
    },
  });

  useEffect(() => {
    const getConferenceInfo = async () => {
      try {
        const token = localStorage.getItem("SavedToken");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/events/event/${id}`,
          {
            headers: {
              authorization: token || "",
            },
          }
        );
        setEvent(response.data.conference);
      } catch (error) {
        console.log(error);
      }
    };

    if (hasRole(['sudo', 'admin', 'msl', 'rep'])) {
      getConferenceInfo();
    } else {
      navigate(`/`);
    }

  }, [id, hasRole, navigate]);

  useEffect(() => {
    if (event) {
      formik.setValues({
        title: event.title,
        purpose: event.purpose,
        timeZone: event.timeZone,
        startTime: utcToZonedTime(new Date(event.start_time), event.timeZone).toString(),
        durationMinutes: event.duration.toString(),
        eventType: event.eventType,
        language: event.language,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (!event) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <>
      <Layout>
        {state.success && !formik.dirty && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {state.success}
          </Alert>
        )}
        {state.error && formik.dirty && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {state.error}
          </Alert>
        )}
        <Container maxWidth={"sm"}>
          <EventForm
            formik={formik}
            edit={true}
            buttonDisabled={state.loading}
          />
        </Container>
      </Layout>
    </>
  );
};

export default EditEventPage;
