import {
  IconButton,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Users } from "../typings/interfaces";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "status"
})<{ status?: string }>(({ status, theme }) => ({
  '&.MuiBadge-root': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignSelf: 'center',
    marginRight: '0px',
    marginLeft: '20px',
  },
  '& .MuiBadge-badge': {
    backgroundColor: status === 'active' ? '#41C12D' : '#EEF643',
    color: '#41C12D',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '16px',
    height: '16px',
    borderRadius: '8px',
  },

}));

type Props = {
  hasRep: boolean,
  rep?: Users,
  refreshReps: () => void,
  conferenceId: string,
};

const RepItem = ({ hasRep, rep, refreshReps, conferenceId }: Props) => {
  const { t } = useTranslation();
  const repId = typeof rep === 'object' ?  rep._id : "";

  //cofnferrenceid userId
  const handleDelete = async () => {
    const token = localStorage.getItem("SavedToken");
    try {
      const confirm = window.confirm(t("delete_confirm_message"));

      if (!confirm) {
        return;
      }

      //remove event invite or remove event from user if the user already exists
      await axios.put(
        `${process.env.REACT_APP_API_URL}/inviterep/${conferenceId}`,
          {
            userId: repId,
            email: rep?.email
          },
          {
            headers: {
              authorization: `${token}`,
            }
          }
      ).then((response) => {
        console.log('rep removed')
        refreshReps();
      });

      
    } catch (error) {
      alert(t("presenter_delete_error"));
    }
  };

  return (
    <ListItem
      sx={{paddingLeft:'5px'}}
      alignItems="flex-start"
      secondaryAction={ hasRep &&
        <IconButton
          edge="end"
          aria-label="delete"
          sx={{marginRight:'30px'}}
          onClick={async () => {
            handleDelete();
          }}
        >
          <DeleteOutlineIcon sx={{fontSize:'30px', color:'#D32F2F',}}/>
        </IconButton>
      }
    >
      { hasRep &&
        <StyledBadge
          status={rep?.status === 'invited' ? 'invited' : 'active'}
          overlap="circular"
          variant="dot"
        >
        </StyledBadge>
      }
      <ListItemText
        sx={{paddingLeft:"20px"}}
        primary={hasRep ? rep?.name : t("no_event_reps")}
        secondary={ hasRep &&
          <React.Fragment>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {rep?.email}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default RepItem;
