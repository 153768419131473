import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";
import { EventPresenter } from "../typings/interfaces";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  handleDialogClose: () => void;
  handleUpdatePresenter: () => void;
  presenter: EventPresenter;
  open: boolean;
};



const EditPresenterDialog = ({
  handleDialogClose,
  handleUpdatePresenter,
  presenter,
  open,
}: Props) => {
  const params = useParams();
  const { t } = useTranslation();
  const [state, setState] = useState({
    error: "",
    loading: false,
  });


  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
    firstName: yup.string().required(t("presenter_invite_generic_error")),
    lastName: yup.string().required(t("presenter_invite_generic_error")),
    displayName: yup.string().required(t("presenter_invite_generic_error")),
    bio: yup
      .string().required(t("presenter_invite_generic_error"))
      .max(1350, t("presenter_invite_form_bio_error")),
    disclosure_1: yup
      .string().required(t("presenter_invite_generic_error"))
      .max(310, t("presenter_invite_form_grants_error")),
    disclosure_2: yup
      .string().required(t("presenter_invite_generic_error"))
      .max(310, t("presenter_invite_form_bureau_error")),
    disclosure_3: yup
      .string().required(t("presenter_invite_generic_error"))
      .max(310, t("presenter_invite_form_consulting_error")),
    disclosure_4: yup
      .string().required(t("presenter_invite_generic_error"))
      .max(310, t("presenter_invite_form_other_error")),
  });

  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      displayName: "",
      bio: "",
      disclosure_1: "",
      disclosure_2: "",
      disclosure_3: "",
      disclosure_4: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      try {
        setState({ ...state, loading: true, error: "" });

        await axios.put(
          `${process.env.REACT_APP_API_URL}/invite/presenter`,
          { ...values, conferenceId: params.id, presenterId: presenter.presenter_id },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );
        handleUpdatePresenter();
        setDisplaySuccess(t("presenter_update_success"));
        setDisplayError("");
        setState({ ...state, loading: false });
        // handleUpdatePresenter(response.data.data);
      } catch (error: any) {
        setState({ error: t("presenter_invite_error"), loading: false });
      }
    },
  });

  useEffect(() => {
    setDisplayError("");
    setDisplaySuccess("");
    if (presenter) {
      formik.setValues({
        firstName: presenter.first_name,
        lastName: presenter.last_name,
        displayName: presenter.display_name,
        bio: presenter.bio,
        disclosure_1: presenter.disclosures.disclosure_1,
        disclosure_2: presenter.disclosures.disclosure_2,
        disclosure_3: presenter.disclosures.disclosure_3,
        disclosure_4: presenter.disclosures.disclosure_4,
        email: presenter.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presenter]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("update_presenter_title")}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleDialogClose}>
            {t("presenter_invite_form_close_btn")}
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Box my={2}>
            {state.error && <Alert severity="error">{state.error}</Alert>}
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  id="email"
                  name="email"
                  label={t("presenter_invite_form_email_label")}
                  placeholder="email@example.com"
                  value={formik.values.email}
                  disabled={true}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="firstName"
                  id="firstName"
                  label={t("presenter_invite_form_first_name_label")}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  name="lastName"
                  id="lastName"
                  label={t("presenter_invite_form_last_name_label")}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  name="displayName"
                  id="displayName"
                  label={t("presenter_invite_form_display_name_label")}
                  value={formik.values.displayName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                  helperText={
                    formik.touched.displayName && formik.errors.displayName
                  }
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  name="bio"
                  id="bio"
                  value={formik.values.bio}
                  onChange={formik.handleChange}
                  error={formik.touched.bio && Boolean(formik.errors.bio)}
                  helperText={formik.touched.bio && formik.errors.bio}
                  label={t("presenter_invite_form_bio_label")}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  name="disclosure_1"
                  id="disclosure_1"
                  value={formik.values.disclosure_1}
                  onChange={formik.handleChange}
                  error={formik.touched.disclosure_1 && Boolean(formik.errors.disclosure_1)}
                  helperText={formik.touched.disclosure_1 && formik.errors.disclosure_1}
                  label={t("presenter_invite_form_grants_label")}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  name="disclosure_2"
                  id="disclosure_2"
                  value={formik.values.disclosure_2}
                  onChange={formik.handleChange}
                  error={formik.touched.disclosure_2 && Boolean(formik.errors.disclosure_2)}
                  helperText={formik.touched.disclosure_2 && formik.errors.disclosure_2}
                  label={t("presenter_invite_form_speakers_label")}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  name="disclosure_3"
                  id="disclosure_3"
                  value={formik.values.disclosure_3}
                  onChange={formik.handleChange}
                  error={formik.touched.disclosure_3 && Boolean(formik.errors.disclosure_3)}
                  helperText={formik.touched.disclosure_3 && formik.errors.disclosure_3}
                  label={t("presenter_invite_form_consulting_label")}
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  multiline
                  name="disclosure_4"
                  id="disclosure_4"
                  value={formik.values.disclosure_4}
                  onChange={formik.handleChange}
                  error={formik.touched.disclosure_4 && Boolean(formik.errors.disclosure_4)}
                  helperText={formik.touched.disclosure_4 && formik.errors.disclosure_4}
                  label={t("presenter_invite_form_other_label")}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Button
              disabled={state.loading}
              type={"submit"}
              variant="contained"
              sx={{ my: 3 }}
            >
              {t("presenter_edit_form_btn")}
            </Button>
          </Box>
        </form>
        <div>
          {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
          {displaySuccess ? (
            <Alert severity="success">{displaySuccess}</Alert>
          ) : (
            ""
          )}
        </div>
      </Container>
    </Dialog>
  );
};

export default EditPresenterDialog;
