import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR,
  GridFooter
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Users } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";

type Props = {
  editUser: (email: string, firstName: string, lastName: string, role: string, _id: string) => void,
  updateTable: () => void,
  update: boolean,
};

const UsersList = ({editUser, updateTable, update}: Props) => {
  const { t, i18n } = useTranslation();
  const email_label = t("user_table_head_email");
  const name_label = t("user_table_head_name");
  const role_label = t("user_table_head_role");
  const edit_label = t("user_table_head_edit");
  const delete_label = t("user_table_head_delete");
  const current_language = i18n.language;
  const [users, setUsers] = useState<Users[]>([]);

  
  const deleteUsers = async (email: string) => {
      const confirm = window.confirm(t("delete_confirm_message"));
  
      if (!confirm) {
        return;
      }
  
      const token = localStorage.getItem("SavedToken");
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/users/delete`, {
          headers: {
            authorization: `${token}`,
          },
          params: {
            email,
          },
        });
        const filteredUsers = users.filter(
          (user) => user.email !== email
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.log(error);
        alert(t("user_delete_error"));
      }
  };

  const getAllUsers = async () => {    
    try {
        const token = localStorage.getItem("SavedToken");
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users`,
            {
                headers: {
                    authorization: `${token}`,
                },
            }
        );
        const usersResponse = response.data.users;
        usersResponse.forEach((userResponse: Users) => {
            userResponse.id = userResponse._id
        });
        usersResponse.reverse();
        setUsers(usersResponse);
    }   catch (error) {
          alert("error");
    }
  }
  
  useEffect(() => {
    getAllUsers();
  },[]);

  useEffect(() => {
    if (update){
      getAllUsers();
      updateTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: email_label,
      resizable: true,
      width: 350,
      editable: false,
    },
    {
      field: "name",
      headerName: name_label,
      resizable: true,
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "role",
      headerName: role_label,
      resizable: true,
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "edit",
      width: 100,
      headerName: edit_label,

      renderCell: (params) => (
      <strong>
          <IconButton
          edge="end"
          aria-label="edit"
          style={{ marginLeft: 0 }}
          onClick={() => editUser(params.row.email, params.row.firstName, params.row.lastName , params.row.role, params.row._id)}
          >
          <EditIcon />
          </IconButton>
      </strong>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      headerName: delete_label,
      width: 100,

      renderCell: (params) => (
      <strong>
          <IconButton
          edge="end"
          aria-label="delete"
          style={{ marginLeft: 0 }}
          onClick={() => {
            deleteUsers(params.row.email);
          }}
          >
            <DeleteIcon />
          </IconButton>
      </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
      return (
          <GridToolbarContainer sx={{justifyContent: "space-between"}}>
            <div>
              <GridToolbarExport
                csvOptions={{
                fields: ["email", "name", "role"],
                }}
                printOptions={{
                fields: ["email", "name", "role"],
                }}
              />
              </div>
              <GridFooter sx={{
                border: 'none', // To delete double border.
              }} />
          </GridToolbarContainer>
      );
  };
    
  return (
    <div style={{ height: 500, width: "100%", marginTop: "20px" }}>
      <DataGrid
        className="user_grid"
        rows={users}
        columns={columns}
        disableSelectionOnClick
        localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomToolbar,
        }}
      />
    </div>
  );
};

export default UsersList;