import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useParams } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { useNavigate } from "react-router-dom";
import { InviteJwtRep } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const RepInviteRegistrationPage = () => {
  const params = useParams();
  const { jwt } = params;
  const { t } = useTranslation();
  const success_message = t("attendee_invite_reg_success");
  const error_message = t("attendee_invite_reg_fail");
  const navigate = useNavigate();


  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
    firstName: yup.string().required(t("presenter_invite_generic_error")),
    lastName: yup.string().required(t("presenter_invite_generic_error")),
    password: yup
    .string()
    .min(8, t("create_user_form_password_error_min_8"))
    .max(13, t("create_user_form_password_error_max_13"))
    .required(t("create_user_form_password_error"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,13})/,
      t("create_user_form_password_error_full")
    ),
    passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  const handleSubmit = async (values: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      axios.post(
        `${process.env.REACT_APP_API_URL}/inviterep`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          name: `${values.firstName} ${values.lastName}`,
          email: values.email,
          password: values.password,
          token:jwt,
        },
      )
      .then(function (response) {
        setState({
          ...state,
          loading: false,
          success: success_message,
        });
        if (response.data.message === "User already exists") {
          setState({ success:"", loading: false, error: t("create_user_form_user_exists") });
        } 
      })
      .catch(function (error) {
        setState({ ...state, loading: false, error: error });
        console.log(error);
      });


      formik.resetForm();
    } catch (error: any) {
      if (error.response) {
        setState({
          ...state,
          error: error.response.data.message,
          loading: false,
        });
        return;
      }
      setState({ ...state, error: error_message, loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if(jwt) {
      const decoded = decode(jwt) as InviteJwtRep;
      const { email, firstName, lastName, language } = decoded;
      formik.setFieldValue("email", email);
      formik.setFieldValue("firstName", firstName);
      formik.setFieldValue("lastName", lastName);
      const currentLanguage = language === "English" ? "en" : "fr";
      i18next.changeLanguage(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  return (
    <Layout style={{display:"flex"}}>
      <Container
      maxWidth={"md"}
      sx={{zIndex:"999", backgroundColor:"#ffffff", boxShadow: "0px 0px 3px 0px rgba(131,129,129,0.50), 0px 7px 10px 0px rgba(226,226,226,0.50)", mt: 3}}
      >
        <Typography align="center" variant="h3" sx={{fontWeight:500, py:"30px"}}>
          {!state.success && t("rep_invite_form_register_title") }
          {state.success && t("rep_invite_form_register_title_success")}
        </Typography>

        <Box my={2}>
          {state.error &&
            <Box my={2}>
              <Alert severity="error">{state.error}</Alert>
            </Box>
          }
          {!state.success && !state.error  ? (
            <Grid container spacing={2}>
              <Grid item md={6}>
                  <TextField
                    required
                    name="firstName"
                    id="firstName"
                    label={t("attendee_invite_form_first_name_label")}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    name="lastName"
                    id="lastName"
                    label={t("attendee_invite_form_last_name_label")}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={t("attendee_invite_form_email_label")}
                    placeholder="email@example.com"
                    value={formik.values.email}
                    disabled
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="password"
                    label={t("create_user_form_password_label")}
                    type="password"
                    color="info"
                    autoComplete="password"
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={formik.touched.password && formik.errors.password}
                    focused
                  />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="passwordConfirmation"
                    label={t("create_user_form_password_confirm_label")}
                    type="password"
                    color="info"
                    variant="outlined"
                    value={formik.values.passwordConfirmation}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)
                    }
                    helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                    focused
                  />
              </Grid>
              <Grid item xs={12} sx={{textAlign:"center"}}>
                <Button
                  disabled={state.loading}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  variant="contained"
                  sx={{ my: 3, width:"200px", p:"10px"}}
                  startIcon={<HowToRegIcon />}
                >
                  {t("attendee_invite_form_register_btn")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div style={{textAlign:"center"}}>
              <Typography align="center" variant="body1" sx={{ my: 3, fontWeight:500, py:"20px", fontSize:"1.2em"}}>
                {state.success && t("rep_invite_form_register_message_success")}
              </Typography>
              <Button
                  onClick={() => navigate("/")}
                  variant="contained"
                  sx={{ my: 3, width:"200px", p:"10px"}}
                >
                  {t("rep_invite_form_login_btn")}
                </Button>
            </div>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default RepInviteRegistrationPage;
