import {
  Box,
  Link,
  Toolbar,
  Grid,
  LinkProps,
  AppBarProps,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  cursor: "pointer",
}));

const FooterBar = styled(Box)<AppBarProps>(({ theme }) => ({
  background: "#fff",
  position: "relative",
}));

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <FooterBar sx={{ boxShadow: 1 }}>
        <Toolbar>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ flexGrow: 1 }} />
            <NavLink
              style={{ marginRight: "75px" }}
              onClick={() => {
                //navigate("/create-event");
              }}
            >
              {t("private_policy")}
            </NavLink>
            <NavLink
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src="/assets/novo-nordisk-logo.jpg"
                alt="Novo Nordisk logo"
                style={{ maxWidth: "60px", marginBottom: 0 }}
              />
            </NavLink>
          </Grid>
        </Toolbar>
      </FooterBar>
    </React.Fragment>
  );
};

export default Footer;
