import {
    Alert,
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    IconButton,
    Typography,
  } from "@mui/material";
  import MenuItem from "@mui/material/MenuItem";
  import Select from "@mui/material/Select";
  import * as yup from "yup";
  import { useFormik } from "formik";
  import { useContext, useState } from "react";
  import AppContext from "../utils/Auth";
  import { formatFormErrors } from "../utils/forms";
  import axios from "axios";
  import Button, { ButtonProps } from "@mui/material/Button";
  import TextField from "@mui/material/TextField";
  import { styled } from "@mui/material/styles";
  import RoundedContainer from "../components/styled/RoundedContainer";
  import { useTranslation } from "react-i18next";
  import CloseIcon from "@mui/icons-material/Close";
  import i18next from "i18next";
  

  const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#fff",
    boxShadow: "unset",
    borderRadius: "4px",
    width: "300px",
    textTransform: "unset",
    "&:hover": {
      backgroundColor: "#01ADF3",
      boxShadow: "unset",
      color: "#fff",
    },
  }));

  type User = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    _id: string;
  }

  type Props = {
    action: string,
    toggleOpen: () => void,
    user: User,
    updateTable: () => void,
  };

  const UserForm = ({ action, toggleOpen, user, updateTable }: Props): JSX.Element => {
    const [displayError, setDisplayError] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState("");
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const language = i18next.language;
    
    const { updateIsAuthenticated, hasRole } = useContext(AppContext);
    const validationSchema = action === "create" ?
    yup.object({
      firstName: yup.string().required(t("presenter_invite_generic_error")),
      lastName: yup.string().required(t("presenter_invite_generic_error")),
      email: yup
        .string()
        .email(t("create_user_form_email_error_2"))
        .required(t("create_user_form_email_error")),
      password: yup
        .string()
        .min(8, t("create_user_form_password_error_min_8"))
        .max(13, t("create_user_form_password_error_max_13"))
        .required(t("create_user_form_password_error"))
        .matches(
          //eslint-disable-next-line
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,13})/,
          t("create_user_form_password_error_full")
        ),
      role: yup.string().required(t("create_user_form_role_error")),
    })
    :
    yup.object({
      firstName: yup.string().required(t("presenter_invite_generic_error")),
      lastName: yup.string().required(t("presenter_invite_generic_error")),
      email: yup
        .string()
        .required(t("create_user_form_email_error")),
      role: yup.string().required(t("create_user_form_role_error")),
    });
  
    const initialValues = action === "create" ? {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      role: "",
    }:
    {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
    };


    const createUser = (values: any, token: any) => {
        try {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/register`,
                {
                  email: values.email.toLowerCase(),
                  firstName: values.firstName,
                  lastName: values.lastName,
                  name: `${values.firstName} ${values.lastName}`,
                  password: values.password,
                  role: values.role,
                  language,
                },
                {
                  headers: {
                    authorization: `${token}`,
                  },
                }
              )
              .then(function (response) {
                console.log('response', response);
                setLoading(false);
                // Todo: t("create_user_form_user_exists"), API only returns english. /register
                if (response.data.message === (t("create_user_form_user_exists"))) {
                  setDisplayError(response.data.message);
                  setDisplaySuccess("");
                } else if (response.data.user) {
                  setDisplaySuccess(t("create_user_form_success") + `${values.firstName} ${values.lastName}`);
                  setDisplayError("");
                  updateTable();
                  formik.resetForm();
                }
              })
              .catch(function (error) {
                setLoading(false);
                console.log(error);
              });
            updateIsAuthenticated(true);
          } catch (error: any) {
            if (error.response.data.errorsValidation) {
              const formattedErrors = formatFormErrors(
                error.response.data.errorsValidation
              );
              formik.setErrors(formattedErrors);
            }
            setLoading(false);
          }
    }
    const editUser = (values: any, token: any) => {
        try {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/users/update/${user._id}`,
                {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  name: `${values.firstName} ${values.lastName}`,
                },
                {
                  headers: {
                    authorization: `${token}`,
                  },
                }
              )
              .then(function (response) {
                setLoading(false);
                if (response.data.message) {
                  setDisplayError(response.data.message);
                  setDisplaySuccess("");
                } else if (response.data.user) {
                  setDisplaySuccess(t("edit_user_form_success") + `${values.firstName} ${values.lastName}`);
                  setDisplayError("");
                  //refresh table
                  updateTable();
                  setTimeout(() => {
                    toggleOpen();
                  }, 1500);
                }
              })
              .catch(function (error) {
                setLoading(false);
                console.log(error);
              });
            updateIsAuthenticated(true);
          } catch (error: any) {
            if (error.response.data.errorsValidation) {
              const formattedErrors = formatFormErrors(
                error.response.data.errorsValidation
              );
              formik.setErrors(formattedErrors);
            }
            setLoading(false);
          }
    }

    const formik = useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        const token = localStorage.getItem("SavedToken");
        setLoading(true);
        action === "create" ? createUser(values, token) : editUser(values, token);
      },
    });
  
    return (
        <Box my={2} sx={{width:"100%", mx:2}}>
          <RoundedContainer style={{ position: "relative", width:"100%"}} maxWidth={"sm"}>
            <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleOpen}
                  aria-label="close"
                  style={{ margin:0}}
              >
                  <CloseIcon />
              </IconButton>
            </div>
            <Typography variant="h4" style={{ textAlign: "center", marginBottom:25, fontWeight:"500" }}>
              {action === 'create' ? t("create_user_form_title") : t("edit_user_page_title")}
            </Typography>
            <div>
              {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
              {displaySuccess ? (
                <Alert sx={{marginBottom:"20px"}} severity="success">{displaySuccess}</Alert>
              ) : (
                ""
              )}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                spacing={3}
                justifyContent="center"
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="firstName"
                    label={t("create_user_form_first_name_label")}
                    type="input"
                    color="info"
                    variant="outlined"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                    focused
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="lastName"
                    label={t("create_user_form_last_name_label")}
                    type="input"
                    color="info"
                    variant="outlined"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    focused
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="email"
                    label={t("create_user_form_email_label")}
                    type="email"
                    color="info"
                    variant="outlined"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    focused
                    disabled={action === "edit" ? true : false}
                  />
                </Grid>
                {action === "create" &&
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        size="medium"
                        id="password"
                        label={t("create_user_form_password_label")}
                        type="password"
                        color="info"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                        formik.touched.password && Boolean(formik.errors.password)
                        }
                        helperText={formik.touched.password && formik.errors.password}
                        focused
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    focused
                    variant="filled"
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    disabled={action === "edit" ? true : false}
                  >
                    <InputLabel> {t("create_user_form_role_label")} </InputLabel>
                    <Select
                      id="role"
                      name="role"
                      placeholder="Select One"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                    >
                      <MenuItem value="msl">{t("create_user_form_role_msl")}</MenuItem>
                      <MenuItem value="rep">{t("create_user_form_role_rep")}</MenuItem>
                      {hasRole(["sudo"]) && (
                        <MenuItem value="admin">{t("create_user_form_role_admin")}</MenuItem>
                      )}
                    </Select>
                    {formik.touched.role && (
                      <FormHelperText>{formik.errors.role}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 20 }}>
                  <CustomButton 
                    fullWidth 
                    variant={"contained"} 
                    type={"submit"}
                    disabled={loading}>
                  {action === "create" ? t("create_user_form_button") : t("edit_user_form_button")}
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </RoundedContainer>
        </Box>
    );
  };
  
  export default UserForm;
