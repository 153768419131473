import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useParams } from "react-router-dom";
import { decode } from "jsonwebtoken";
import { useNavigate } from "react-router-dom";
import { ResetJwtRep } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ResetPassword = () => {
  const params = useParams();
  const { jwt } = params;
  const [email, setEmail] = useState("");
  const [type, setType] =  useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();


  const validationSchema = yup.object({
    password: yup
    .string()
    .min(8, t("create_user_form_password_error_min_8"))
    .max(13, t("create_user_form_password_error_max_13"))
    .required(t("create_user_form_password_error"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,13})/,
      t("create_user_form_password_error_full")
    ),
    passwordConfirmation: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
  });

  useEffect(() => {
    if(jwt) {
      const decoded = decode(jwt) as ResetJwtRep;
      const { language, type } = decoded;
      setEmail(decoded.email);
      setType(type);
      const currentLanguage = language === "en" ? "en" : "fr";
      i18next.changeLanguage(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);
  


  const handleErrors = (error: string, type: string) => {
    let error_message = '';

    switch(error) {
      case 'You cannot use the same account password.':
        error_message = t("reset_password.error.same_password");
        break;
      case 'jwt expired':
        error_message = t("reset_password.error.expired_token");
        break;
      case 'invalid signature':
        error_message = type === 'set' ? t("set_password.error.invalid_token") : t("reset_password.error.invalid_token");
        break;
      default:
        error_message = type === 'set' ? t("set_password.error.general") : t("reset_password.error.general");
    }

    setState({ success:"", loading: false, error: error_message });
  }

  const handleSubmit = async (values: {
    password: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      axios.post(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          email: email || '',
          password: values.password,
          token:jwt,
        },
      )
      .then(function (response) {
        if (response.data) {
          type === 'set' ?
          setState({ success: t("set_password.success_message"), loading: false, error: "" })
          :
          setState({ success: t("reset_password.success_message"), loading: false, error: "" })
        } 
      })
      .catch(function (error) {
        if(error.response.data) {
          handleErrors(error.response.data.error.message, type);
        }
      });

      formik.resetForm();

    } catch (error: any) {
        setState({
          ...state,
          error: t("reset_password.error.general"),
          loading: false,
        });
        return;
    }
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Layout style={{display:"flex"}}>
      <Container
      maxWidth={"md"}
      sx={{zIndex:"999", backgroundColor:"#ffffff", boxShadow: "0px 0px 3px 0px rgba(131,129,129,0.50), 0px 7px 10px 0px rgba(226,226,226,0.50)", mt: 3}}
      >
        <Typography align="center" variant="h3" sx={{fontWeight:500, py:"30px"}}>
          { type === 'set' ? t("set_password.title") : t("forgot_password.title")}
        </Typography>

        <Box my={2}>
            <Box pb={3}>
              { state.error && <Alert severity="error">{state.error}</Alert>}
              { state.success && <Alert severity="success">{state.success}</Alert>}
              { state.success && 
                <div style={{textAlign:"center"}}>
                  <Button
                    onClick={() => {
                      navigate('/')
                    }}
                    variant="contained"
                    sx={{ my: 3, width:"200px", p:"10px"}}
                  >
                    {t("signin")}
                  </Button>
                </div>
              }
            </Box>
          { state.success === "" &&
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="password"
                  label={t("create_user_form_password_label")}
                  type="password"
                  color="info"
                  autoComplete="password"
                  variant="outlined"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  focused
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="passwordConfirmation"
                  label={t("create_user_form_password_confirm_label")}
                  type="password"
                  color="info"
                  variant="outlined"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)
                  }
                  helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                  focused
                />
              </Grid>
              <Grid item xs={12} sx={{textAlign:"center"}}>
                <Button
                  disabled={state.loading}
                  onClick={() => {
                    formik.submitForm();
                  }}
                  variant="contained"
                  sx={{ my: 3, width:"auto", p:"10px 20px", backgroundColor: "#158BEE"}}
                  startIcon={<HowToRegIcon />}
                >
                  {type === 'set' ? t("set_password.submit_btn") : t("forgot_password.submit_btn")}
                </Button>
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
    </Layout>
  );
};

export default ResetPassword;
