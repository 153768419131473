type ValidationErrors = {
  [key: string]: string[];
};

type FormattedError = {
  [key: string]: string;
};

export const formatFormErrors = (
  validationErrors: ValidationErrors
): FormattedError => {
  const formattedErrors: FormattedError = {};

  Object.keys(validationErrors).forEach((key) => {
    formattedErrors[key] = validationErrors[key].join(", ");
  });

  return formattedErrors;
};
