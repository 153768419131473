import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR
} from "@mui/x-data-grid";
import { Alert, IconButton, Snackbar, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@mui/icons-material/MailOutline";
import { Registrant } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import frenchLocale from 'date-fns/locale/fr-CA';
import { useState } from "react";

type ResendInvite = {
  success: boolean;
  email: string;
}

type Props = {
  registrants: Registrant[];
  deleteRegistrants: (email: string) => void;
  handleAddAttendee: (attendee: any) => Promise<ResendInvite>;
};

const RegistrantsList = ({ registrants, deleteRegistrants, handleAddAttendee }: Props) => {
  const { t, i18n } = useTranslation();
  const email_label = t("registrant_table_head_email");
  const full_name_label = t("registrant_table_head_full_name");
  const status_label = t("registrant_table_head_status");
  const resend_invite_label = t("registrant_table_head_resend_invite");
  const invite_sent_date = t("registrant_table_head_invite_sent_date");
  const invite_count = t("registrant_table_head_invite_count");
  const event_type_head = t("registrant_table_head_event_type");
  const delete_label = t("registrant_table_head_delete");
  const current_language = i18n.language;
  const [showSuccess, updateShowSuccess] = useState({
    success:false,
    show: false, 
    email: ''
  });

  const getFrenchStatus = (status:string) => {
    return status === 'invited' ? 'INVITÉ(E)' : 'ACCEPTÉ';
  }

  const formatDate = (date: Date) => {
    return current_language === "en" ? format(new Date(date), "MMMM dd, YYY h:mm aa") : format(new Date(date), "dd MMMM, YYY H 'h 'mm", { locale: frenchLocale })
  }

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: email_label,
      width: 275,
      editable: false,
    },
    {
      field: "full_name",
      headerName: full_name_label,
      width: 175,
      editable: false,
      flex: 1,
      renderCell: (params) => <p>{params.row.first_name} { params.row.last_name }</p>,
      valueGetter: (params) => `${params.row.first_name} ${ params.row.last_name }`
    },
    {
      field: "status",
      headerName: status_label,
      width: 100,
      editable: false,
      renderCell: (params) => <p>{current_language === "en" ? params.row.status.toUpperCase() : getFrenchStatus(params.row.status)}</p>,
      valueGetter: (params) => current_language === "en" ? params.row.status.toUpperCase() : getFrenchStatus(params.row.status),
    },
    {
      field: "updatedAt",
      headerName: invite_sent_date,
      width: 200,
      editable: false,
      renderCell: (params) => (
          <p>{params.row.updatedAt && formatDate(params.row.updatedAt)}</p>
      ),
      valueGetter: (params) => (
        params.row.updatedAt && formatDate(params.row.updatedAt)
      )
    },
    {
      field: "counter",
      headerName: invite_count,
      width: 95,
      editable: false,
    },
    {
      field: "eventType",
      headerName: event_type_head,
      width: 100,
      editable: false,
      renderCell: (params) => <p>{params.row.eventType ? t(params.row.eventType) : ""}</p>,
      valueGetter: (params) => t(params.row.eventType),
    },
    {
      field: "resend",
      headerName: resend_invite_label,
      width: 165,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: (params) => (
         <p>{params.row.status === 'invited' &&

          <IconButton
            edge="end"
            aria-label={t('invite_attendee_button')}
            style={{
              textTransform:"uppercase",
              marginLeft: 0,
              fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
              fontWeight: 500,
              fontSize: "0.875rem",
              color: "#ffffff",
              backgroundColor: "#158bee",
              borderRadius: "5px",
              padding: "5px 10px"
            }}
            size="small"
            onClick={() => {
              const attendee = {
                email: params.row.email, 
                firstName: params.row.first_name, 
                lastName: params.row.last_name, 
                status: params.row.status
              }
              handleAddAttendee(attendee).then(function(response:any) {
                updateShowSuccess({
                  success: response.success,
                  show: true,
                  email: params.row.email, 
                });
              });
            }}
            >
            <EmailIcon sx={{width: "0.875em"}} /> <span style={{marginLeft:"5px", fontSize: "0.875rem", fontWeight:500}}>{t('invite_attendee_button')}</span>
          </IconButton>
         }
         </p>
      ),
    },
    {
      sortable: false,
      editable: false,
      filterable: false,
      field: "delete",
      width:70,
      headerName: delete_label,

      renderCell: (params) => (
        <strong>
          <IconButton
            edge="end"
            aria-label="delete"
            style={{ marginLeft: 0 }}
            onClick={() => {
              deleteRegistrants(params.row.email);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fields: ["email", "full_name", "status", "eventType", "counter", "updatedAt"],
          }}
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
            fields: ["email", "full_name", "status", "eventType", "counter", "updatedAt"],
          }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <>
    <div>
      <Snackbar 
        open={showSuccess.show} 
        autoHideDuration={6000} 
        onClose={() => updateShowSuccess({success:false, show:false, email:''})} 
        sx={{
        position: "relative",
        left: "0px!important",
        top: "0px",
        marginBottom: "0px",
        }}>
        <Alert onClose={() => updateShowSuccess({success:false, show:false, email:''})} severity={showSuccess.success ? "success" : "error" } sx={{ width: '100%' }}>
          {showSuccess.success ? t("attendee_invite_resend_success_message") : t("attendee_invite_resend_error_message")} {showSuccess.email}
        </Alert>
      </Snackbar>
    </div>
    <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
      <DataGrid
        className="registrant_grid"
        rows={registrants}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        disableSelectionOnClick
        localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomToolbar,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No registrants
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No registrants match the filter
            </Stack>
          )
        }}
      />
    </div>
    </>
  );
};

export default RegistrantsList;
