import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { EventPresenter } from "../typings/interfaces";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from "axios";
import { useTranslation } from "react-i18next";
import AppContext from "../utils/Auth";

type Props = {
  presenter: EventPresenter;
  handleEditPresenter: (id: string) => void;
  handleDeletePresenter: (id: []) => void;
  conferenceId: string;
};

const PresenterItem = ({ presenter, handleEditPresenter, handleDeletePresenter, conferenceId }: Props) => {
  const { t } = useTranslation();
  const { hasRole } = useContext(AppContext);

  const handleDelete = async (id: string) => {
    const token = localStorage.getItem("SavedToken");
    try {
      const confirm = window.confirm(t("delete_confirm_message"));

      if (!confirm) {
        return;
      }

     const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/invite/presenter/${id}/${conferenceId}`,
        {
          headers: {
            authorization: `${token}`,
          },
          data: { conferenceId },
        }
      );

      handleDeletePresenter(response.data.data);

    } catch (error) {
      // TODO: replace with MUI alert
      alert(t("presenter_delete_error"));
    }
  };

  return (
      <ListItem
        alignItems="flex-start"
        secondaryAction={ hasRole(["sudo", "admin", "msl", "rep"]) && (
          <div className="presenter_actions_wrapper">
            <IconButton
              edge="end"
              aria-label="edit"
              sx={{marginRight:'10px'}}
              color="warning"
              onClick={() => handleEditPresenter(presenter.presenter_id)}
            >
              <EditIcon sx={{fontSize:'30px'}}/>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              sx={{marginRight:'30px'}}
              onClick={async () => {
                handleDelete(presenter.presenter_id);
              }}
            >
              <DeleteOutlineIcon sx={{fontSize:'30px', color:'#D32F2F'}}/>
            </IconButton>
          </div>
        )
        }
      >
        <ListItemAvatar>
          <Avatar
            sx={{ border: "1px solid #e0e0e0" }}
            alt={presenter.display_name}
            src={presenter.presenter_image_url}
          />
        </ListItemAvatar>
        <ListItemText
          primary={presenter.display_name}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {presenter.email}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
  );
};

export default PresenterItem;
