import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  enUS,
  frFR
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { Attendee } from "../typings/interfaces";
import { useTranslation } from "react-i18next";

type Props = {
  attendees: Attendee[];
};

const AttendeesList = ({ attendees }: Props) => {
  const { t, i18n } = useTranslation();
  const email_label = t("attendee_table_head_email");
  const first_name_label = t("attendee_table_head_first_name");
  const last_name_label = t("attendee_table_head_last_name");
  const attended_label = t("attendee_table_head_attended");
  const engaged_label = t("attendee_table_head_engaged");
  const current_language = i18n.language;

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: email_label,
      width: 350,
      editable: false,
      flex: 1,
    },
    {
      field: "first_name",
      headerName: first_name_label,
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "last_name",
      headerName: last_name_label,
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "attended?",
      headerName: attended_label,
      width: 200,
      editable: false,
      flex: 1,
    },
    {
      field: "engaged",
      headerName: engaged_label,
      width: 200,
      editable: false,
      flex: 1,
      renderCell: (params) => <p>{ params.row.engaged ? params.row.engaged : 'N/A' }</p>,
      valueGetter: (params) =>  params.row.engaged ? params.row.engaged : 'N/A',
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fields: ["email", "first_name", "last_name", "attended?", "engaged"],
          }}
          printOptions={{
            hideToolbar: true,
            hideFooter: true,
            fields: ["email", "first_name", "last_name", "attended?", "engaged"],
          }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: 400, width: "100%", marginTop: "20px" }}>
      <DataGrid
        className="registrant_grid"
        rows={attendees}
        columns={columns}
        disableSelectionOnClick
        localeText={current_language === 'en' ? enUS.components.MuiDataGrid.defaultProps.localeText : frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{
          Footer: CustomToolbar,
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No attendees
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No attendees match the filter
            </Stack>
          )
        }}
      />
    </div>
  );
};

export default AttendeesList;
