import { BrowserRouter, Route, Routes as Router } from "react-router-dom";
import EventsPage from "./pages/Events";
import LoginPage from "./pages/LoginPage";
import { useContext } from "react";
import AppContext from "./utils/Auth";
import ViewEventPage from "./pages/ViewEvent";
import CreateEventPage from "./pages/CreateEventPage";
import EditEventPage from "./pages/EditEvent";
import RegistrationPage from "./pages/RegistrationPage";
import RepInviteRegistrationPage from "./pages/RepInviteRegistrationPage";
import ViewUsersPage from "./pages/ViewUsers";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";
import SettPage from "./pages/Sett";

const Routes = (): JSX.Element => {
  const { isAuthenticated } = useContext(AppContext); 

  return (
    <BrowserRouter>
      <Router>
        {isAuthenticated ? (
          <>
            <Route path="/" element={<EventsPage />} />
            <Route path="/event/:id" element={<ViewEventPage />} />
            <Route path="/edit/event/:id" element={<EditEventPage />} />
            <Route path="/users" element={<ViewUsersPage />} />
            <Route path="/create-event" element={<CreateEventPage />} />
            <Route path="/sett-page/" element={<SettPage />} />
          </>
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
          </>
        )}
        <Route path="/invite/:jwt" element={<RegistrationPage />} />
        <Route path="/invite-rep/:jwt" element={<RepInviteRegistrationPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:jwt" element={<ResetPassword />} />
        <Route path="/set-password/:jwt" element={<ResetPassword />} />

      </Router>
    </BrowserRouter>
  );
};

export default Routes;
