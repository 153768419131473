import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useDropzone } from "react-dropzone";
import BulkInvite from "./BulkInvite";
import { useTranslation } from "react-i18next";
// import csvTemplate from "../sett/attendee-bulk-invite-template.csv";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  handleDialogClose: () => void;
  refreshRegistrants: () => void;
  handleAddAttendee: (value: any) => void;
  open: boolean;
};


const InviteAttendeeDialog = ({
  handleDialogClose,
  refreshRegistrants,
  handleAddAttendee,
  open,
}: Props) => {
  const params = useParams();
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  useDropzone({
    onDrop,
    accept: [".csv"],
    maxFiles: 1,
    maxSize: 1000000,
  });

  const [state, setState] = useState({
    error: "",
    loading: false,
    errorBulk: "",
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setState({ ...state, loading: true, error: "" });
        handleAddAttendee(values);
        setState({ ...state, loading: false });
        formik.resetForm();
        handleDialogClose();
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
        setState({ ...state, error: "Request failed", loading: false });
      }
    },
  });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t("attendee_invite_page_title")}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleDialogClose}>
            {t("presenter_invite_form_close_btn")}
          </Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth={"sm"}>
        <Box my={2}>
          {state.error && <Alert severity="error">{state.error}</Alert>}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                required
                id="email"
                name="email"
                label={t("attendee_invite_form_email_label")}
                placeholder="email@example.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                name="firstName"
                id="firstName"
                label={t("attendee_invite_form_first_name_label")}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                name="lastName"
                id="lastName"
                label={t("attendee_invite_form_last_name_label")}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                fullWidth
              />
            </Grid>
          </Grid>

          <Button
            onClick={() => {
              formik.submitForm();
            }}
            disabled={state.loading}
            variant="contained"
            sx={{ my: 3 }}
            startIcon={<SendIcon />}
          >
            {t("attendee_invite_form_btn")}
          </Button>
        </Box>
        <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
          {t("attendee_invite_page_or")}
        </Typography>
        <BulkInvite
          conferenceId={params.id || ""}
          onSuccess={() => {
            handleDialogClose();
            refreshRegistrants();
          }}
        />
        <Button
          variant="contained"
          component={Link}
          sx={{ m: 3 }}
          startIcon={<FileDownloadOutlinedIcon />}
          href={`${process.env.PUBLIC_URL}/assets/csv-template/attendee-bulk-invite-template.csv`}
          download="registrant-csv-template.csv"
        >
          {t("attendee_invite_form_download_csv_template")}
        </Button>
      </Container>
    </Dialog>
  );
};

export default InviteAttendeeDialog;
