/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";

type Role = "sudo" | "admin" | "msl" | "rep";

interface IAppContext {
  roles: Role[];
  updateIsAuthenticated(isAuthenticated: boolean): void;
  isAuthenticated: boolean;
  hasRole(roles: string[]): boolean;
  userName: string;
  loading: boolean;
  updateRoles: (roles: Role[]) => void;
  logout: () => void;
}

//Initial App Context
export const AppContext = createContext<IAppContext>({
  roles: [],
  isAuthenticated: false,
  updateIsAuthenticated: function (): void {
    // do nothing
  },
  hasRole: function (): boolean {
    return false;
  },
  userName: "",
  loading: false,
  updateRoles: function (): void {},
  logout: function (): void {},
});

export const AppProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const updateIsAuthenticated = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
  };

  const hasRole = (checkRoles: string[]) => {
    return roles.some((role) => checkRoles.includes(role));
  };

  const updateRoles = (roles: Role[]) => {
    setRoles(roles);
  };

  const logout = () => {
    localStorage.removeItem("SavedToken");
    localStorage.removeItem("role");
    updateRoles([]);
    updateIsAuthenticated(false);
  };

  useEffect(() => {
    //Poll the local storage
      const token = localStorage.getItem("SavedToken") || null;
      const role = localStorage.getItem("role") || "";

      updateRoles([role as Role]);

      // If token log user in
      if (token) {
        setIsAuthenticated(true);
      }
  }, []);

  useEffect(() => {
    console.log("Logged In");
  }, [isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        roles,
        updateIsAuthenticated,
        isAuthenticated,
        hasRole,
        userName,
        loading,
        updateRoles,
        logout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
