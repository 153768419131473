import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useTranslation } from "react-i18next";
import Layout from "../components/Layout";
import UsersList from "../components/UsersList";
import UserForm from "../components/UserForm";
import { useState } from "react";

type User = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    _id: string;
  }

const ViewUsers = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('create');
    const [user, setUser] = useState<User>({email: "", firstName: "", lastName:"", role: "", _id: ""})
    const [update, setUpdate] = useState(false);

    const editUser = (email: string, firstName: string, lastName: string, role: string, _id: string) => {
        setAction('edit');
        setOpen(!open)
        setUser({email, firstName, lastName, role, _id});
    };

    const createUser = () => {
        setOpen(!open)
        setAction('create');
    };
    
    const toggleOpen = () => setOpen(!open);

    const updateTable = () => {
        setUpdate(!update)
    }

    return (
        <Layout>    
            <Box my={3}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography 
                            variant="h4" 
                            color="#3D3D3D" 
                            sx={{
                                marginTop: "15px", 
                                borderBottom: "5px solid #158BEE",
                                fontWeight:"500"
                            }}>
                            {t("users_title")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                        color="primary"
                        variant="contained"
                        sx={{
                            marginTop: "30px",
                            backgroundColor: "#158BEE",
                            borderRadius: "4px",
                            width: "300px",
                        }}
                        startIcon={<PersonAddAltIcon />}
                        onClick={createUser}
                        >
                        {t("create_user_form_title")}
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    item
                    sx={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        backgroundColor: "#FFF",
                    }}
                >
                    <UsersList editUser={editUser} updateTable={updateTable} update={update}/>
                </Grid>
                <Modal
                    open={open}
                    onClose={toggleOpen}
                    onBackdropClick={toggleOpen}
                    style={{display:'flex',alignItems:'center',justifyContent:'center'}}
                >
                    <UserForm action={action} toggleOpen={toggleOpen} user={user} updateTable={updateTable}/>
                </Modal>
            </Box>
        </Layout>
    );
}

export default ViewUsers

