import { useContext } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Event } from "../typings/interfaces";
import { formatInTimeZone } from 'date-fns-tz';
import frenchLocale from 'date-fns/locale/fr-CA';
import { Box, CardActions, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AppContext from "../utils/Auth";

type Props = {
  event: Event;
  onEventDelete: (eventId: string) => void;
};

export default function EventCard({ event, onEventDelete }: Props) {
  const navigate = useNavigate();
  const { hasRole } = useContext(AppContext);
  const { t } = useTranslation();

  const timeZoneArray = event.timeZone.split("/");
  const timeZone = timeZoneArray[1];
  const translatedTimeZone = t(`time_zones.${timeZone}`)

  const deleteEvent = async (id: string) => {
    const confirm = window.confirm(t("delete_confirm_message"));

    if (!confirm) {
      return;
    }

    try {
      const token = localStorage.getItem("SavedToken");
      await axios.delete(`${process.env.REACT_APP_API_URL}/events/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      onEventDelete(id);
    } catch (error) {
      alert(t("delete_event_fail_messsage"));
    }
  };

  return (
    <Card sx={{ height: "100%", zIndex: 5, position: "relative", display:'flex', flexDirection: 'column' }}>
      <Box my={2} px={2}>
        <CardHeader
          sx={{padding:'0px', paddingBottom:'16px' }}
          title={event.title}
          subheader={i18next.language === "en" ? formatInTimeZone(event.start_time, event.timeZone, "MMMM dd, YYY") : formatInTimeZone(new Date(event.start_time), event.timeZone, "dd MMMM, YYY", { locale: frenchLocale })}
        />
        <Typography variant="body2" color="text.secondary">
          <Box display="flex" alignItems="center" sx={{marginBottom:'5px'}}>
            <AccessTimeIcon sx={{ mr: 1 }} />
            {t("start_time")} {i18next.language === "en" ? 
              `${formatInTimeZone(new Date(event.start_time), event.timeZone, "h:mm aa")} ${translatedTimeZone}` : 
              `${formatInTimeZone(new Date(event.start_time), event.timeZone, "H 'h 'mm")}, ${translatedTimeZone}`
            }
          </Box>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Box display="flex" alignItems="center">
            <TimelapseIcon sx={{ mr: 1 }} />
            {t("duration")} {event.duration} {t("minutes")}
          </Box>
        </Typography>
        {event.purpose && 
          <Typography variant="body2" color="text.secondary" sx={{marginTop:"5px"}}>
            <Box display="flex" alignItems="center">
              <LocationOnOutlinedIcon sx={{ mr: 1, alignSelf:"flex-start" }} />
              {event.purpose}
            </Box>
          </Typography>
        }
      </Box>

      <CardContent sx={{
        flex: 1, 
        display: "flex",
        alignItems: "flex-end"
      }}>
        <Typography variant="body2" color="text.secondary">
          {event.eventType ? `${t("view_event_event_type")} ${t(`${event.eventType}`)}` : t("no_type_provided")}
        </Typography>
      </CardContent>
      <CardActions sx={{ backgroundColor: "#e0e0e0" }}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            navigate(`/event/${event.id}`);
          }}
        >
          <PersonAddAlt1Icon />
        </IconButton>
        { hasRole(['sudo', 'admin', 'msl', 'rep']) &&
            <IconButton
              size="small"
              color="warning"
              onClick={() => {
                navigate(`/edit/event/${event.id}`);
              }}
            >
              <EditIcon />
            </IconButton>
          }
          { hasRole(['sudo', 'admin', 'msl']) &&
            <IconButton
              size="small"
              color="error"
              onClick={() => {
                deleteEvent(event.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          }
      </CardActions>
    </Card>
  );
}