import { Box, Grid, Link, Typography } from "@mui/material";
import { useEffect } from "react";
import Layout from "../components/Layout";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from "react-i18next";
import LiveEventPDF from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Live_EN_V01.pdf";
import VirtualEventPDF from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Virtual_EN_V01.pdf";
import HybridEventPDF from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Hybrid_EN_V01.pdf";
import LiveEventPDF_FR from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Live_FR_V01.pdf";
import VirtualEventPDF_FR from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Virtual_FR_V01.pdf";
import HybridEventPDF_FR from "../sett/NNCI-WEG-P657-ATLAS-Participant-Invitation_Hybrid_FR_V01.pdf";
import i18next from "i18next";

const SettPage = () => {
  const { t } = useTranslation();
  const language = i18next.language
  
  const downloadArrayEN = [
    {
      downloadPDF: LiveEventPDF,
      downloadText: t("SETT.live")
    },
    {
      downloadPDF: VirtualEventPDF,
      downloadText: t("SETT.virtual")
    },
    {
      downloadPDF: HybridEventPDF,
      downloadText: t("SETT.hybrid")
    },
  ]
  const downloadArrayFR = [{
    downloadPDF: LiveEventPDF_FR,
    downloadText: t("SETT.live")
  },
  {
    downloadPDF: VirtualEventPDF_FR,
    downloadText: t("SETT.virtual")
  },
  {
    downloadPDF: HybridEventPDF_FR,
    downloadText: t("SETT.hybrid")
  }]

  const downloadArray = language === "en" ? downloadArrayEN : downloadArrayFR;

  useEffect(()=> window.scrollTo(0, 0),[]);


  return (
    <Layout>
      <Grid container spacing={30}>
        <Grid item xs={12}></Grid>
          {downloadArray.map((eventType) => 
          <Grid item xs={4} sx={{zIndex:'1'}}>
            <Link href={eventType.downloadPDF} download sx={{textDecoration: "none"}}> 
              <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <FileDownloadOutlinedIcon sx={{fontSize: '100px', color: '#158BEE'}}/>
                  <Typography variant="h4" align="center" color={"#158BEE"} key={eventType.downloadText}>{eventType.downloadText}</Typography>
              </Box>
            </Link>
          </Grid>
          )}
        <Grid item xs={12}></Grid>
      </Grid>
    </Layout>
  );
};

export default SettPage;
