import React from "react";
import { Alert, Box, FormControl, FormHelperText, Grid, InputLabel } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import AppContext from "../utils/Auth";
import { format } from 'date-fns';
import { formatFormErrors } from "../utils/forms";
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Layout from "../components/Layout";
import Button, { ButtonProps } from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import RoundedContainer from "../components/styled/RoundedContainer";
import { useTranslation } from "react-i18next";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "#158BEE",
  color: "#fff",
  boxShadow: "unset",
  borderRadius: "4px",
  width: "300px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#01ADF3",
    boxShadow: "unset",
    color: "#fff",
  },
}));

const CreateEventPage = (): JSX.Element => {
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  // const [date, setDate] = React.useState<Date | null>(new Date());
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { updateIsAuthenticated } = useContext(AppContext);

  

  const validationSchema = yup.object({
    title: yup.string().required(t("create_event_form_title_error")),
    timeZone: yup.string().required(t("create_event_form_timzone_error")),
    startTime: yup.date()
    // .min(new Date(), t("create_event_form_date_past_error"))
    .required(t("create_event_form_date_error")),
    durationMinutes: yup.number().required(t("create_event_form_duration_error")),
    eventType: yup.string().required(t("create_type_error")),
    venue: yup.string()
    .when('eventType', {
      is: (eventType:string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string()
        .required(t("create_event_form_venue_error")),
    }),
    address: yup.string()
    .when('eventType', {
      is: (eventType:string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string()
        .required(t("create_event_form_address_error")),
    }),
    city: yup.string()
    .when('eventType', {
      is: (eventType:string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string()
        .required(t("create_event_form_city_error")),
    }),
    province: yup.string()
    .when('eventType', {
      is: (eventType:string) => eventType === "Live" || eventType === "Hybrid",
      then: yup.string()
        .required(t("create_event_form_province_error")),
    }),
    postal: yup.string()
      .when('eventType', {
        is: (eventType:string) => eventType === "Live" || eventType === "Hybrid",
        then: yup.string()
          .required(t("create_event_form_postal_error")),
      })
  });

  const formik = useFormik({
    initialValues: {
      channelId: "operatic",
      title: "",
      timeZone: "",
      startTime: new Date(new Date().getTime() + 2 * 60000),
      durationMinutes: "",
      venue: "",
      address: "",
      city: "",
      province: "",
      postal: "",
      eventType: "",
      language: t("language"),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      try {
        setDisplayError("");
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/events`,
          {
            channelId: values.channelId,
            title: values.title,
            timeZone: values.timeZone,
            startTime: format(values.startTime, "yyyy-MM-dd HH:mm"),
            durationMinutes: values.durationMinutes,
            eventType: values.eventType,
            language: values.language,
            purpose: `${values.eventType === "Virtual" ? "" : `${values.venue.trim()}, ${values.address.trim()}, ${values.city.trim()}, ${values.province}, ${values.postal.trim()}`}`,
          },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );

        setDisplaySuccess(t("create_event_form_success") + response.data.title);
        setLoading(false);
        updateIsAuthenticated(true);
      } catch (error: any) {
        if (error.response) {
          if (error.response.data.errorsValidation) {
            const formattedErrors = formatFormErrors(
              error.response.data.errorsValidation
            );
            formik.setErrors(formattedErrors);
            return;
          }
          setLoading(false);
        }
        setDisplayError(t("create_forms_request_fail"));
      }
    },
  });

  return (
    <Layout>
      <Box my={2}>
        <RoundedContainer maxWidth="sm">
          <p style={{ textAlign: "center", marginTop: 50, fontSize: 20 }}>
            {t("create_event_page_title")}
          </p>
          <div>
            {displayError ? <Alert severity="error">{displayError}</Alert> : ""}
            {displaySuccess ? (
              <Alert severity="success">{displaySuccess}</Alert>
            ) : (
              ""
            )}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={3}
              style={{ marginTop: "25px" }}
              justifyContent="center"
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="medium"
                  id="title"
                  label={t("create_event_form_title")}
                  type="text"
                  color="info"
                  autoComplete="title"
                  variant="outlined"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  focused
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  focused
                  variant="filled"
                  error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
                >
                  <InputLabel> {t("create_event_form_timezone")} </InputLabel>
                  <Select
                    id="timeZone"
                    name="timeZone"
                    type="text"
                    color="info"
                    value={formik.values.timeZone}
                    onChange={formik.handleChange}
                    error={formik.touched.language && Boolean(formik.errors.timeZone)}
                  >
                    <MenuItem value="Eastern Time (US & Canada)">{t("time_zones.Eastern")}</MenuItem>
                    <MenuItem value="Pacific Time (US & Canada)">{t("time_zones.Pacific")}</MenuItem>
                    <MenuItem value="Central Time (US & Canada)">{t("time_zones.Central")}</MenuItem>
                    <MenuItem value="Atlantic Time (Canada)">{t("time_zones.Atlantic")}</MenuItem>
                    <MenuItem value="Newfoundland">{t("time_zones.Newfoundland")}</MenuItem>
                    <MenuItem value="Mountain Time (US & Canada)">{t("time_zones.Mountain")}</MenuItem>
                  </Select>
                  {formik.touched.timeZone && (
                    <FormHelperText>{formik.errors.timeZone}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} justifyContent="center">
                <FormControl
                  fullWidth
                  focused
                  variant="filled"
                  error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      disablePast
                      minDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          error={
                            formik.touched.startTime &&
                            Boolean(formik.errors.startTime)
                          }
                          helperText={
                            formik.touched.startTime && formik.errors.startTime
                          }
                        />
                      )}
                      label={t("create_event_form_date_label")}
                      value={formik.values.startTime}
                      onChange={(value) => {
                        if (value) {
                          // let date = new Date(value)
                          formik.setFieldValue("startTime", value);
                        } else {
                          formik.setFieldValue("startTime", "");
                        }
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="medium"
                  id="durationMinutes"
                  name="durationMinutes"
                  label={t("create_event_form_duration_label")}
                  type="number"
                  color="info"
                  autoComplete="durationMinutes"
                  variant="outlined"
                  value={formik.values.durationMinutes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.durationMinutes &&
                    Boolean(formik.errors.durationMinutes)
                  }
                  helperText={
                    formik.touched.durationMinutes &&
                    formik.errors.durationMinutes
                  }
                  focused
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  focused
                  variant="filled"
                  error={formik.touched.eventType && Boolean(formik.errors.eventType)}
                >
                  <InputLabel> {t("create_type")} </InputLabel>
                  <Select
                    id="eventType"
                    name="eventType"
                    type="text"
                    color="info"
                    value={formik.values.eventType}
                    onChange={formik.handleChange}
                    error={formik.touched.language && Boolean(formik.errors.eventType)}
                  >
                    <MenuItem value="Live">{t("Live")}</MenuItem>
                    <MenuItem value="Virtual">{t("Virtual")}</MenuItem>
                    <MenuItem value="Hybrid">{t("Hybrid")}</MenuItem>
                  </Select>
                  {formik.touched.eventType && (
                    <FormHelperText>{formik.errors.eventType}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {formik.values.eventType !== "Virtual" && <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="venue"
                    name="venue"
                    label={t("create_event_form_venue_label")}
                    type="text"
                    color="info"
                    autoComplete="venue"
                    variant="outlined"
                    value={formik.values.venue}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.venue &&
                      Boolean(formik.errors.venue)
                    }
                    helperText={
                      formik.touched.venue &&
                      formik.errors.venue
                    }
                    focused
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="address"
                    name="address"
                    label={t("create_event_form_address_label")}
                    type="text"
                    color="info"
                    autoComplete="address"
                    variant="outlined"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address &&
                      Boolean(formik.errors.address)
                    }
                    helperText={
                      formik.touched.address &&
                      formik.errors.address
                    }
                    focused
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="city"
                    name="city"
                    label={t("create_event_form_city_label")}
                    type="text"
                    color="info"
                    autoComplete="city"
                    variant="outlined"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.city &&
                      Boolean(formik.errors.city)
                    }
                    helperText={
                      formik.touched.city &&
                      formik.errors.city
                    }
                    focused
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="province"
                    name="province"
                    label={t("create_event_form_province_label")}
                    type="text"
                    color="info"
                    autoComplete="province"
                    variant="outlined"
                    value={formik.values.province}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.province &&
                      Boolean(formik.errors.province)
                    }
                    helperText={
                      formik.touched.province &&
                      formik.errors.province
                    }
                    focused
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="postal"
                    name="postal"
                    label={t("create_event_form_postal_label")}
                    type="text"
                    color="info"
                    autoComplete="postal"
                    variant="outlined"
                    value={formik.values.postal}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.postal &&
                      Boolean(formik.errors.postal)
                    }
                    helperText={
                      formik.touched.postal &&
                      formik.errors.postal
                    }
                    focused
                  />
                </Grid> 
              </>}
              
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  focused
                  variant="filled"
                  error={formik.touched.language && Boolean(formik.errors.language)}
                >
                  <InputLabel> {t("create_language")} </InputLabel>
                  <Select
                    id="language"
                    name="language"
                    type="text"
                    color="info"
                    value={formik.values.language}
                    onChange={formik.handleChange}
                    error={formik.touched.language && Boolean(formik.errors.language)}
                  >
                    <MenuItem value="English">{t("English")}</MenuItem>
                    <MenuItem value="French">{t("French")}</MenuItem>
                  </Select>
                  {formik.touched.language && (
                    <FormHelperText>{formik.errors.language}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginTop: 20 }}>
                <CustomButton 
                  fullWidth 
                  variant={"contained"} 
                  type={"submit"}
                  disabled={loading}>
                   {t("create_event_form_button")}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </RoundedContainer>
      </Box>
    </Layout>
  );
};

export default CreateEventPage;
