import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Layout from "../components/Layout";
import axios from "axios";
import EventCard from "../components/EventCard";
import { FilterEvent } from "../typings/types";
import { Event } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AppContext from "../utils/Auth";

const EventsPage = (): JSX.Element => {
  const { hasRole } = useContext(AppContext);
  const [events, setEvents] = useState<Event[]>([]);
  const [filter, setFilter] = useState<FilterEvent>("all");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getAllEvents = async (page: number = 1, pageCount: number = 12) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("SavedToken");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/events`,
        {
          params: {
            type: filter,
            page,
            pageCount,
          },
          headers: {
            authorization: `${token}`,
          },
        }
      );
      const { conferences, total_pages } = response.data;
      setEvents(conferences);
      setTotalPages(total_pages);
      setLoading(false);
    } catch (error) {
      // TODO: better language for error
      setError("Failed retrieving events");
      setLoading(false);
    }
  };

  const onEventDelete = (eventId: string) => {
    const filteredEvents = events.filter((x) => x.id !== eventId);
    setEvents(filteredEvents);
  };

  useEffect(() => {
    setError("");
    getAllEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onPaginationChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    //Get new page data
    setError("");
    getAllEvents(page);
  };

  return (
    <Layout>
      <>
        <Grid justifyContent="centre" style={{}}>
          <Typography
            variant="h2"
            py={2}
            style={{
              textAlign: "center",
              marginTop: "55px",
              color: "#3D3D3D",
              fontSize: "50px",
              fontWeight: "500",
            }}
          >
            {t("events")}
          </Typography>
        </Grid>
        <Grid
          justifyContent="center"
          display="flex"
          style={{ marginTop: "31px" }}
        >
          <Chip
            label={t("all")}
            sx={{
              margin: 1,
              "&.MuiChip-filled": {
                backgroundColor: "#2196f3",
                border: "2px solid #e0e0e0",
                color: "#fff",
              },
            }}
            variant={filter === "all" ? "filled" : "outlined"}
            onClick={() => setFilter("all")}
          />
          <Chip
            label={t("happening_now")}
            variant={filter === "happening_now" ? "filled" : "outlined"}
            sx={{
              margin: 1,
              border: "2px solid #158BEE",
              "&.MuiChip-filled": {
                backgroundColor: "#158BEE",
                color: "#fff",
              },
            }}
            onClick={() => setFilter("happening_now")}
          />
          <Chip
            label={t("future")}
            variant={filter === "future" ? "filled" : "outlined"}
            sx={{
              margin: 1,
              border: "2px solid #7ABDFF",
              "&.MuiChip-filled": {
                backgroundColor: "#7ABDFF",
                color: "#fff",
              },
            }}
            onClick={() => setFilter("future")}
          />
          <Chip
            label={t("past")}
            variant={filter === "past" ? "filled" : "outlined"}
            sx={{
              margin: 1,
              border: "2px solid #969696",
              "&.MuiChip-filled": {
                backgroundColor: "#969696",
              },
            }}
            onClick={() => setFilter("past")}
          />
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          { hasRole(['sudo', 'admin', 'msl']) &&
            <Chip
              label={t("create_event")}
              color="success"
              sx={{ margin: 1 }}
              onClick={() => navigate("/create-event")}
              icon={<AddIcon />}
            />
          }
        </Grid>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="red" variant="body2">
            {error}
          </Typography>
        ) : (
          <Grid container spacing={2} py={2}>
            {events.length === 0 && (
              <Grid item>
                <Typography>{t("no_events_found")}</Typography>{" "}
              </Grid>
            )}
            {events.map((event) => (
              <Grid item md={4} sm={6} xs={12} key={event.id}>
                <EventCard event={event} onEventDelete={onEventDelete} />
              </Grid>
            ))}
          </Grid>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <Pagination
            count={totalPages}
            onChange={onPaginationChange}
            disabled={totalPages === 1}
            hidden={events.length === 0}
          />
        </Box>
      </>
    </Layout>
  );
};

export default EventsPage;
