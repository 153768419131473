import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useParams, useSearchParams } from "react-router-dom";
import { decode } from "jsonwebtoken";
import frenchLocale from 'date-fns/locale/fr-CA';
import { InviteJwt } from "../typings/interfaces";
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from "date-fns-tz";
import i18next from "i18next";

const RegistrationPage = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { jwt } = params;
  const eventType = searchParams.get("eventType");
  const { t } = useTranslation();
  const success_message = t("attendee_invite_reg_success");
  const error_message = t("attendee_invite_reg_fail");

  const translateResponseMessage = (msg:string) => {
    if( msg === 'Expired Invite') {
      return t("attendee_expired_invite_msg")
    }
    else {
      return msg;
    }
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
    firstName: yup.string().required(t("presenter_invite_generic_error")),
    lastName: yup.string().required(t("presenter_invite_generic_error")),
  });

  const [state, setState] = useState({
    error: "",
    success: "",
    loading: false,
    eventId: "",
    title: "",
    startTime: "",
    timeZone: "",
    isFilled: false,
  });

  const handleSubmit = async (values: {
    email: string;
    firstName: string;
    lastName: string;
  }) => {
    try {
      setState({ ...state, loading: true, error: "" });
      await axios.post(
        `${process.env.REACT_APP_API_URL}/invite/attendee/register`,
        { ...values, conferenceId: state.eventId, eventType: eventType }
      );
      setState({
        ...state,
        loading: false,
        success: success_message,
      });
      formik.resetForm();
    } catch (error: any) {
      if (error.response) {
        setState({
          ...state,
          error: translateResponseMessage(error.response.data.message),
          loading: false,
        });
        return;
      }
      setState({ ...state, error: error_message, loading: false });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (jwt) {
      const decoded = decode(jwt) as InviteJwt;
      const { event, email, eventId, firstName, lastName } = decoded;
      formik.setFieldValue("email", email);
      formik.setFieldValue("firstName", firstName);
      formik.setFieldValue("lastName", lastName);
      const isFilled = eventId && email && firstName && lastName ? true : false;
      const timeZoneArray = event.timeZone.split("/");
      const timeZone = timeZoneArray[1];
      const timeZoneDisplay = t(`time_zones.${timeZone}`);
      const currentLanguage = event.language === "English" ? "en" : "fr";
      i18next.changeLanguage(currentLanguage);

      setState({
        ...state,
        eventId,
        title: event.title,
        startTime: event.language === "English" ? formatInTimeZone(new Date(event.start_time), event.timeZone, "MMMM dd, yyyy hh:mm aa") : `${formatInTimeZone(event.start_time, event.timeZone, 'iiii,', { locale: frenchLocale })} le ${formatInTimeZone(event.start_time, event.timeZone, 'd MMMM yyyy', { locale: frenchLocale })} ${formatInTimeZone(event.start_time, event.timeZone, "H 'h 'mm")}`,
        timeZone: timeZoneDisplay,
        isFilled,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  useEffect(() => {
    if (state.isFilled) {
      //Auto Submit
      formik.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isFilled]);

  return (
    <Layout>
      <Container maxWidth={"sm"}>
        <Typography variant="h4" sx={{ mt: 3 }}>
          {t("attendee_invite_form_register_btn")}: {state.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 5 }}>
          {t("attendee_invite_form_date_time_label")}
          {state.startTime &&
            `${state.startTime} ${state.timeZone}`
          }
        </Typography>

        <Box my={2}>
          <Box my={2}>
            {state.success && <Alert severity="success">{state.success}</Alert>}
            {state.error && <Alert severity="error">{state.error}</Alert>}
          </Box>
          {!state.isFilled || !state.success ? (
            <>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label={t("attendee_invite_form_email_label")}
                    placeholder="email@example.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    name="firstName"
                    id="firstName"
                    label={t("attendee_invite_form_first_name_label")}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    required
                    name="lastName"
                    id="lastName"
                    label={t("attendee_invite_form_last_name_label")}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Button
                disabled={state.loading}
                onClick={() => {
                  formik.submitForm();
                }}
                variant="contained"
                sx={{ my: 3 }}
                startIcon={<HowToRegIcon />}
              >
                {t("attendee_invite_form_register_btn")}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default RegistrationPage;
