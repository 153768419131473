import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { useTranslation } from "react-i18next";
import RoundedContainer from "./styled/RoundedContainer";


type Props = {
  toggleModal: () => void,
  refreshReps: () => void,
};

const AssociateRepForm = ({ toggleModal, refreshReps }: Props) => {
  const params = useParams();
  const { t } = useTranslation();

  const [state, setState] = useState({
    error: "",
    loading: false,
    errorBulk: "",
  });

  const validationSchema = yup.object({
    firstName: yup
    .string()
    .required(t("presenter_invite_generic_error")),
    lastName: yup
    .string()
    .required(t("presenter_invite_generic_error")),
    email: yup
      .string()
      .email(t("create_user_form_email_error_2"))
      .required(t("create_user_form_email_error")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const token = localStorage.getItem("SavedToken");
      try {
        setState({ ...state, loading: true, error: "" });
        await axios.put(
          `${process.env.REACT_APP_API_URL}/users/associateEvent`,
          { ...values, email: values.email.toLowerCase(), conference_id: params.id },
          {
            headers: {
              authorization: `${token}`,
            },
          }
        );
        
        refreshReps();
        setState({ ...state, loading: false });
        toggleModal();
        formik.resetForm();
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
        setState({ ...state, error: "Request failed", loading: false });
      }
    },
  });

  return (
    <Box my={2} sx={{width:"100%", mx:2}}>
      <RoundedContainer style={{ position: "relative"}} maxWidth={"sm"}>
        <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleModal}
            aria-label="close"
            style={{ margin:0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Typography variant="h4" style={{ textAlign: "center", marginBottom:25, fontWeight:"500" }}>
          {t("add_rep_modal_title")}
        </Typography>

          {state.error && <Alert severity="error">{state.error}</Alert>}
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                name="firstName"
                id="firstName"
                label={t("presenter_invite_form_first_name_label")}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                }
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="lastName"
                id="lastName"
                label={t("presenter_invite_form_last_name_label")}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                required
                id="email"
                name="email"
                label={t("attendee_invite_form_email_label")}
                placeholder="email@example.com"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
          </Grid>

          <Button
            onClick={() => {
              formik.submitForm();
            }}
            disabled={state.loading}
            variant="contained"
            sx={{ my: 3 }}
            startIcon={<SendIcon />}
          >
            {t("add_rep_button")}
          </Button>
      </RoundedContainer>
    </Box>
  );
};

export default AssociateRepForm;
