import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = () => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{ textAlign: "center" }}
      my={4}
    >
      <CircularProgress sx={{ my: 2 }} />
      <Typography>Loading...</Typography>
    </Box>
  );
};

export default Loading;
