import {
  Box,
  Link,
  Toolbar,
  Grid,
  LinkProps,
  Button,
  ButtonGroup,
  ButtonProps,
  BoxProps,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AppContext from "../utils/Auth";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddIcon from "@mui/icons-material/Add";
import EventIcon from "@mui/icons-material/Event";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: "#158BEE",
  color: "#fff",
  boxShadow: "unset",
  borderRadius: "4px",
  textTransform: "unset",
  "&:hover": {
    backgroundColor: "#01ADF3",
    boxShadow: "unset",
    color: "#fff",
  },
}));

const NavLink = styled(Link)<LinkProps>(({ theme }) => ({
  color: "#3D3D3D",
  margin: "0 15px",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    marginRight: "5px",
  },
}));

const Header = styled(Box)<BoxProps>(({ theme }) => ({
  color: "#FFFFFF",
}));

const languages = [
  {
    code: "en",
    name: "EN",
  },
  {
    code: "fr",
    name: "FR",
  },
];

const Nav = () => {
  const { isAuthenticated, hasRole, logout } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = cookies.get("i18next") || "en";

  return (
    <React.Fragment>
      <Header
        sx={{
          backgroundColor: "#fff",
          zIndex: 5,
          position: "relative",
          py: 2,
          boxShadow: 1,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item display={"flex"} alignItems="center">
              <NavLink
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src="/assets/obesity-atlas-logo.svg"
                  alt="Obesity Atlas logo"
                  style={{
                    maxWidth: "200px",
                    marginBottom: 0,
                    color: "#3D3D3D",
                  }}
                />
              </NavLink>

              {isAuthenticated && (
                <NavLink
                  style={{ marginLeft: "106px" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <EventIcon />
                  {t("events")}
                </NavLink>
              )}

              {isAuthenticated && hasRole(["sudo", "admin", "msl"]) && (
                <NavLink
                  style={{ marginLeft: "40px" }}
                  onClick={() => {
                    navigate("/create-event");
                  }}
                >
                  <AddIcon />
                  {t("create_event")}
                </NavLink>
              )}

              {isAuthenticated && hasRole(["sudo", "admin"]) && (
                <NavLink
                  style={{ marginLeft: "40px" }}
                  onClick={() => {
                    navigate("/users");
                  }}
                >
                  <PersonOutlineIcon />
                  {t("users_title")}
                </NavLink>
              )}
            </Grid>
            <Grid item display={"flex"}>
              {isAuthenticated && (
                <NavLink
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                >
                  {t("logout")}
                </NavLink>
              )}

              <ButtonGroup
                disableElevation
                variant="contained"
                sx={{ border: "1px solid #fff" }}
              >
                {languages.map(({ code, name }) => (
                  <CustomButton
                    onClick={() => i18next.changeLanguage(code)}
                    disabled={code === currentLanguage}
                  >
                    {name}
                  </CustomButton>
                ))}
              </ButtonGroup>

            </Grid>
          </Grid>
        </Toolbar>
      </Header>
    </React.Fragment>
  );
};

export default Nav;
